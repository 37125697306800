<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.getDetail"
    :before-close="handleClose"
    destroy-on-close
    :track="{
      name_zh: '基础数据/货主相关/货主信息/抽屉-货主详情',
      trackName: $route.path
    }"
    title="货主信息"
    size="1111px"
  >
    <h2 class="title-company-name">
      {{ `${baseData.customerName || ''}（${baseData.creditCode || ''}）` }}
    </h2>
    <el-form
      ref="enterpriseForm"
      :model="formData"
      class="owner-detail"
      label-position="right"
      label-suffix=":"
    >
      <nh-drawer-container title="基础数据">
        <el-row>
          <el-col
            v-for="(item, index) in baseInfo"
            :key="index"
            :span="item.span"
          >
            <el-form-item :label="item.label">
              {{ baseData[item.prop] }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营业执照">
              <div class="files">
                <div class="midea-box">
                  <img
                    :src="baseData.businessLicenseUrl"
                    alt=""
                    @click="dialogVisible = true"
                  >
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </nh-drawer-container>
      <el-dialog v-model="dialogVisible">
        <img
          class="dialog-img"
          :src="baseData.businessLicenseUrl"
          alt=""
        >
      </el-dialog>
    </el-form>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          @click="handleClose"
        >
          关闭
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import { BUSINESS_LIST_ENUM, COOPERATIVE_NATURE_ENUM } from '../constant';
import { INDUSTRY_LIST_ENUM } from './constant';

import {
  baseInfo,
  billingInfo,
} from './config';
import { getCustomerDetail } from './api';

export default {
  name: 'ShipperDetail',
  mixins: [loadingMixin],
  data() {
    return {
      drawerVisible: false,
      dialogVisible: false,
      baseInfo,
      billingInfo,
      baseData: {},
      kData: {},
      loading: {
        getDetail: false,
      },
    };
  },
  methods: {
    init(id) {
      this.getDetail(id);
      this.drawerVisible = true;
    },
    async getDetail(id) {
      const result = await getCustomerDetail({ customerId: id });
      this.baseData = {
        ...result,
        shipperType: COOPERATIVE_NATURE_ENUM[result.shipperType],
        address: `${result.provinceName || ''}${result.cityName || ''}${result.countyName || ''}${result.address || ''}`,
        businessLicenseUrl: result.ext.businessLicenseUrl || '',
        industry: INDUSTRY_LIST_ENUM[result.ext.industry],
        bizType: BUSINESS_LIST_ENUM[result.ext.bizType],
      };
      this.kData = {
        ...result,
      };
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title-company-name {
  display: inline-block;
  margin-bottom: 21px;
  font-size: 20px;
  font-weight: 600;
  color: #171717;
  line-height: 28px;
}

.owner-detail {
  .dialog-img {
    width: 100%;
  }

  .files {
    display: flex;
    flex-wrap: wrap;

    .midea-box {
      box-sizing: border-box;
      width: 80px;
      height: 80px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
<style lang="scss">
.owner-detail {
  .el-form-item__label {
    color: #606266;
  }

  .el-form-item__content {
    color: #171717;
  }
}
</style>
