import { wmsPlusHttp } from '@/utils/http/index';
/**
 * @description: 货品分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/271/interface/api/9292
 */
export function getGoodsLevelTree(data) {
  return wmsPlusHttp.post('/goods_level/tree', data);
}

/**
 * @description: 货品三级分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/19265
 */
export function getGoodsLevel(data) {
  return wmsPlusHttp.post('/warehouse_foundation/goods_level/tree', data);
}

/**
 * @description: 下架  库存分配规则 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11516
 */

export function ruleStockAllocation(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_stock_allocation/page', data, {
    params,
  });
}
/**
 * @description: 上架规则 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/16311
 */

export function ruleUpperPage(data, params) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_upper/page', data, {
    params,
  });
}
