<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.init"
    :before-close="handleClose"
    :track="{
      name_zh: `基础数据/货主相关/货主信息/抽屉-${isEdit? '编辑':'新增'}`,
      trackName: $route.path
    }"
    destroy-on-close
    title="货主信息"
    size="1111px"
  >
    <el-form
      ref="enterpriseForm"
      class="owner-shipper-create"
      :model="formData"
      label-suffix="："
      :rules="rules"
      label-position="left"
    >
      <nh-drawer-container
        title="基础数据"
      >
        <el-row class="el-form--label-top">
          <el-col :span="8">
            <el-form-item
              label="货主自有编码"
              prop="customerCode"
            >
              <el-input
                v-model="formData.customerCode"
                :disabled="isEdit"
                maxlength="30"
                placeholder="请输入客户方编码"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="货主名称" prop="customerName">
              <el-input
                v-model="formData.customerName"
                maxlength="30"
                placeholder="请输入货主名称，长度不超过30位"
              />
              <!-- <el-autocomplete
                v-model="formData.customerName"
                :fetch-suggestions="handleQuerySearchName"
                placeholder="请输入货主名称，长度不超过30位"
                maxlength="30"
                value-key="label"
                @select="handleSelect"
              /> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业名称" prop="corpName">
              <el-autocomplete
                v-model="formData.corpName"
                :fetch-suggestions="handleQuerySearchName"
                placeholder="请输入货主名称，长度不超过30位"
                maxlength="30"
                value-key="label"
                @select="handleSelect"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="统一社会信用代码"
              prop="creditCode"
              :error="creditCodeError"
            >
              <nh-CreditCodeInput
                v-model="formData.creditCode"
                prop="creditCode"
                @select="handleSelect"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="el-form--label-top">
          <el-col :span="8">
            <el-form-item label="联系人姓名" prop="contacts">
              <el-input v-model="formData.contacts" maxlength="30" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人手机号" prop="telPhone">
              <el-input
                v-model="formData.telPhone"
                maxlength="11"
                type="phone"
                placeholder="仅支持数字"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="行业类型" prop="industryTypeCode">
              <el-select
                v-model="formData.industryTypeCode"
                placeholder="请选择"
                @change="industryChange"
              >
                <el-option
                  v-for="item in INDUSTRY_LIST"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="el-form--label-top">
          <el-col :span="8">
            <el-form-item
              label="联系人地址"
              required
            >
              <div class="input-line">
                <el-form-item prop="provinces">
                  <el-cascader
                    v-model="formData.provinces"
                    placeholder="请选择省市区"
                    filterable
                    :options="cityList"
                    @change="handleChangeCity"
                  />
                </el-form-item>
                <el-form-item prop="address">
                  <el-autocomplete
                    v-model="formData.address"
                    placeholder="请输入关键词搜索"
                    :trigger-on-focus="false"
                    :fetch-suggestions="handleChangeAddr"
                    @select="handleSelectAddr"
                  >
                    <template #default="{item}">
                      <span>{{ item.name }}<span class="orig-address">
                        {{ item.address }}-{{ item.typeLev3 }}
                      </span></span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="el-form--label-top">
          <el-form-item label="营业执照" prop="ext.businessLicenseUrl">
            <div class="files">
              <div
                :class="[
                  'midea-box',
                  !formData.ext.businessLicenseUrl &&
                    !loading.uploadFile? 'el-upload el-upload--picture-card'
                  :'el-upload-list--picture-card']"
                @click="() =>{if(!formData.ext.businessLicenseUrl) {handleUpload()}} "
              >
                <div v-if="loading.uploadFile" class="el-upload-list__item is-ready">
                  <el-progress
                    type="circle"
                    :percentage="file.percentage"
                    :width="60"
                  />
                </div>

                <template v-else-if="!formData.ext.businessLicenseUrl">
                  <i class="el-icon-plus" />
                </template>
                <template v-else>
                  <div class="el-upload-list__item is-ready">
                    <img
                      :src="formData.ext.businessLicenseUrl"
                      alt=""
                    >
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview"
                      >
                        <i class="el-icon-zoom-in" />
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleUpload"
                      >
                        <i class="el-icon-upload" />
                      </span>
                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove"
                      >
                        <i class="el-icon-delete" />
                      </span>
                    </span>
                  </div>
                </template>
              </div>
            </div>
            <input
              ref="inputFile"
              class="file-input"
              type="file"
              name="file"
              accept=".png, .jpg"
              @change="beforeAvatarUpload"
            >
            <span class="describe">支持jpg、png，最大不超过5M</span>
          </el-form-item>
        </el-row>
      </nh-drawer-container>
      <el-dialog v-model="dialogVisible">
        <img
          class="dialog-img"
          :src="formData.ext.businessLicenseUrl"
          alt=""
        >
      </el-dialog>
    </el-form>
    <template #footer>
      <div v-if="!disabled" class="footer">
        <nh-button @click="handleClose">
          取消
        </nh-button>
        <nh-button
          v-loading="loading.handleSaveSubmit"
          type="primary"
          @click="handleSaveSubmit"
        >
          保存
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import { getToken } from '@/utils/auth';
import creditCode from '@/thales/CreditCodeInput/api';
import {
  getCustomerDetail,
  editCustomer,
  getCityAreaTree,
  poiSearch,
  enterprisetTwoElements,
} from './api';
import env from '../../../../../../env/index';
import { COOPERATIVE_NATURE_ENUM } from '../constant';
import {
  SHIPPER_TYPE_ENUM, SHIPPER_TYPE,
  BUSINESS_LIST, COOPERATIVE_NATURE,
  INDUSTRY_LIST,
  IMAGE_TYPE, PASS,
  COOPERATIVE_CODE_ENUM,
  INDUSTRY_LIST_ENUM,
} from './constant';
import { rules } from './config';

async function getCreditCode(queryString) {
  let option = [];
  const res = await creditCode({
    corpNameLike: queryString,
  }) || [];
  if (res.length > 0) {
    option = res.map((list) => ({
      ...list,
      value: list.corpName,
      label: `${list.corpName}(${list.creditCode})`,
    }));
  } else {
    option = [{
      value: queryString,
      label: '未查询到相关企业，将以此企业名称创建并认证',
      corpName: queryString,
    }];
  }
  return option;
}

// 新增编辑货主WMS的专用标签
const SOURCE_WMS_WEB = 'SOURCE_WMS_WEB';
export default {
  name: 'OwnerShipperCreate',
  mixins: [loadingMixin],
  emits: ['onSuccess'],

  data() {
    return {
      drawerVisible: false,
      dialogVisible: false,
      disabled: false,
      isEdit: false,
      rules,
      creditCodeError: '',
      SHIPPER_TYPE,
      BUSINESS_LIST,
      COOPERATIVE_NATURE,
      INDUSTRY_LIST,
      INDUSTRY_LIST_ENUM,
      uploadHeader: {
        Authorization: getToken(),
      },
      file: {
        percentage: 0,
      },
      cityList: [],
      level: 3,
      formData: {
        customerType: SHIPPER_TYPE_ENUM.company, // 货主类型
        customerExt: {
          shipperType: null,
        },
        customerCode: null,
        customerName: null,
        creditCode: null,
        contacts: null,
        telPhone: null,
        provinces: [],
        address: null,
        ext: {
          // bizType: null,
          businessLicenseUrl: null,
        },
        // invoice: null,
        // taxpayerNo: null,
        // invoiceAddress: null,
        // invoiceTelPhone: null,
        // bank: null,
        // account: null,
        industryType: null,
        industryTypeCode: null,
      },
      loading: {
        init: false,
        uploadFile: false,
        handleSaveSubmit: false,
      },
    };
  },
  created() {
    this.getCityTree();
  },
  methods: {
    async init(id) {
      this.drawerVisible = true;
      const form = {
        customerType: SHIPPER_TYPE_ENUM.company, // 货主类型
        customerExt: {
          shipperType: 1,
        },
        customerCode: null,
        customerName: null,
        creditCode: null,
        contacts: null,
        telPhone: null,
        provinces: [],
        address: null,
        ext: {
          businessLicenseUrl: null,
        },
        industryType: null,
        industryTypeCode: null,
      };
      if (id) {
        this.isEdit = true;
        const formData = await getCustomerDetail({ customerId: id });
        const provinces = [formData.provinceCode, formData.cityCode, formData.countyCode];
        this.formData = {
          ...form,
          ...formData,
          customerExt: {
            shipperType: formData.customerExt?.shipperType,
          },
          ext: {
            businessLicenseUrl: formData.ext?.businessLicenseUrl,
          },
          provinces,
        };
      } else {
        this.isEdit = false;
        this.formData = form;
      }
    },
    async checkCreditCode() {
      this.creditCodeError = '';
      let flag = true;
      try {
        const resp = await enterprisetTwoElements({
          verifyType: 1,
          verifyName: this.formData.corpName,
          creditCode: this.formData.creditCode,
        });
        flag = resp?.authResult === PASS;
      } catch (error) {
        flag = false;
      }
      this.creditCodeError = flag ? '' : '验证失败';
      return flag;
    },
    async handleQuerySearchName(queryString, cb) {
      const options = queryString ? await getCreditCode(queryString) : [];
      cb(options);
    },
    handleSelect(item) {
      if (item.corpName) {
        this.formData.corpName = item.corpName;
        // 应base要求，新增编辑时corpName和customerAllName保持一致
        this.formData.customerAllName = item.corpName;
      }
      if (item.creditCode) {
        this.formData.creditCode = item.creditCode;
        // 应base要求，新增编辑时creditCode和uniformCreditCode保持一致
        this.formData.uniformCreditCode = item.creditCode;
      }
    },
    async getCityTree() {
      const res = await getCityAreaTree({
        filterBlackList: true,
      });
      const city = JSON.parse(JSON.stringify(res));
      this.cityList = this.filterData(city, 1);
    },
    filterData(data, level) {
      data.forEach((e) => {
        e.label = e.areaName;
        e.value = e.areaCode;
        if (level === this.level) {
          e.children = null;
        }
        if (level <= this.level && e.children && e.children.length > 0) {
          this.filterData(e.children, level + 1);
        }
      });
      return data;
    },
    handleChangeCity(val) {
      this.formData.address = null;
      this.cityList.forEach((province) => {
        if (province.value === val[0]) {
          this.formData.provinceCode = province.value;
          this.formData.provinceName = province.label;
          if (this.level >= 2) {
            province.children.forEach((city) => {
              if (city.value === val[1]) {
                this.formData.cityCode = city.value;
                this.formData.cityName = city.label;
                if (this.level >= 3) {
                  city.children.forEach((county) => {
                    if (county.value === val[2]) {
                      this.formData.countyCode = county.value;
                      this.formData.countyName = county.label;
                    }
                  });
                }
              }
            });
          }
        }
      });
    },
    async handleChangeAddr(val, cb) {
      if (val) {
        const { provinceName, cityName, countyName } = this.formData;
        const city = `${provinceName || ''}${cityName || ''}${countyName || ''}`;
        const res = await poiSearch({
          address: city + val,
          city,
        });
        cb(res || []);
      } else {
        cb([]);
      }
    },
    industryChange(val) {
      this.formData.industryType = this.INDUSTRY_LIST_ENUM[val];
    },
    handleSelectAddr(val) {
      this.formData.address = val.name;
      this.formData.provinces = [val.provinceCode, val.cityCode, val.countyCode];
      this.formData.provinceCode = val.provinceCode;
      this.formData.provinceName = val.province;
      this.formData.cityCode = val.cityCode;
      this.formData.cityName = val.city;
      this.formData.countyCode = val.countyCode;
      this.formData.countyName = val.county;
    },
    handleUpload() {
      this.file.percentage = 0;
      this.$refs.inputFile.click();
    },
    // 上传
    beforeAvatarUpload(e) {
      const file = e.target.files[0];
      const isLt5M = file.size / 1024 / 1024 < 5;
      const fileType = file.type;
      const isImg = IMAGE_TYPE.some((item) => fileType.endsWith(item));
      if (!isImg) {
        this.$message.error('只支持png和jpg格式的文件!');
      } else if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      } else {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const resp = await this.uploadHttp(file);
      if (resp.code === '2000000000') {
        this.formData.ext.businessLicenseUrl = resp.data[0].url;
      } else {
        this.$message.error(resp.msg);
      }
    },
    // 上传请求
    uploadHttp(file) {
      return new Promise((resolve) => {
        const fromData = new FormData();
        const fileExtension = file.name.split('.').pop();
        fromData.append('file', file);
        fromData.append('fileExtension', fileExtension);
        const http = new XMLHttpRequest();
        http.responseType = 'json';
        http.open('POST', `${env.OTMS_WEB_PLUS_SERVER}/bfs_oss/upload`);
        http.setRequestHeader('Authorization', getToken());
        http.onreadystatechange = () => {
          if (http.status === 200 && http.readyState === 4) {
            resolve(http.response);
          }
        };
        // 获取上传进度
        http.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            this.file.percentage = Math.floor((event.loaded / event.total) * 100);
          }
        };
        http.send(fromData);
      });
    },
    // 上传图片预览
    handlePictureCardPreview() {
      this.dialogVisible = true;
    },
    // 删除图片
    handleRemove() {
      this.formData.ext.businessLicenseUrl = null;
      this.$refs.inputFile.value = '';
    },
    // 保存
    async handleSaveSubmit() {
      await this.$refs.enterpriseForm.validate();
      const flag = await this.checkCreditCode();
      if (flag) {
        const formData = {
          ...this.formData,
          cooperativeNature: COOPERATIVE_NATURE_ENUM[this.formData.customerExt.shipperType],
          cooperativeNatureCode: COOPERATIVE_CODE_ENUM[this.formData.customerExt.shipperType],
          bizType: this.formData.bizType ? this.formData.bizType : 'TRANSPORT_WAREHOUSE',
          tags: [SOURCE_WMS_WEB],
        };
        await editCustomer(formData);
        this.$emit('onSuccess');
        this.handleClose();
      }
    },
    handleClose() {
      this.$refs.enterpriseForm.resetFields();
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.owner-shipper-create {
  .dialog-img {
    width: 100%;
  }

  .input-line {
    display: flex;

    .el-form-item {
      margin-bottom: 0;
    }

    & > :last-of-type {
      margin-right: 0;
    }
  }
}

.files {
  display: flex;
  flex-wrap: wrap;

  .midea-box {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
    width: 80px;
    height: 80px;
    display: inline-block;
    border-radius: 6px;
    line-height: 80px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .midea-box:active {
    z-index: 1;
    transform: scale(1);
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
  }

  .el-upload-list--picture-card .el-progress {
    width: auto;
  }

  .el-upload--picture-card i {
    font-size: 18px;
    margin-top: 0;
  }

  .el-upload-list--picture-card .el-upload-list__item-actions {
    font-size: 18px;

    span + span {
      margin-left: 8px;
    }
  }
}

.files + .file-input {
  display: none;
}

.describe {
  font-size: 12px;
  color: #969799;
  line-height: 20px;
}

.footer {
  border-top: 1px solid #eee;
  padding: 8px 24px 8px 0;
  // background-color: #fff;
  text-align: right;
}
</style>
<style lang="scss">
.owner-shipper-create {
  .el-select {
    width: 100%;
  }

  .el-input {
    line-height: 32px;
  }

  .el-autocomplete {
    width: 100%;
  }
}
</style>
