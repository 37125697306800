import { FormType } from '@/constant/form';
import { CUSTOMER_STATUSES, CUSTOMER_STATUSES_ENUM } from './constant';

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '货主编码',
  prop: 'shipperCode',
  minWidth: 240,
}, {
  label: '货主名称',
  prop: 'shipperName',
  minWidth: 180,
}, {
  label: '企业名称',
  prop: 'corpName',
  minWidth: 180,
}, {
  label: '统一社会信用代码',
  prop: 'creditCode',
  minWidth: 180,
}, {
  label: '联系人姓名',
  prop: 'contacts',
  minWidth: 180,
}, {
  label: '联系人手机号',
  prop: 'contactNumber',
  minWidth: 180,
}, {
  label: '状态',
  prop: 'statusName',
  formatter: ({ statusCode }) => (statusCode === '1' ? CUSTOMER_STATUSES_ENUM.ENABLED : CUSTOMER_STATUSES_ENUM.DISABLED),
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 180,
}];

export const serchFields = [
  {
    label: '货主编码',
    prop: 'customerCode',
    component: FormType.INPUT,
  }, {
    label: '货主名称',
    prop: 'customerNameLike',
    component: FormType.INPUT,
  }, {
    label: '联系人',
    prop: 'contactsLike',
    component: FormType.INPUT,
  }, {
    label: '状态',
    prop: 'status',
    component: FormType.SELECT,
    options: CUSTOMER_STATUSES,
  },
];
