<template>
  <div>
    <pl-block style="margin-bottom: 20px;">
      <PlForm
        v-model="searchForm"
        mode="search"
        :fields="serchFields"
        @submit="handleSearch"
        @reset="handleSearch"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getReservoirList"
        :data="reservoirList"
        :columns="TABLECOLUMN"
        :show-table-setting="true"
        @update:pagination="getReservoirList"
      >
        <template #tableHeaderLeft>
          <nh-button
            type="primary"
            :track="{
              trackName: '基础数据/货主相关/货主信息/[新增]'
            }"
            @click="handleAddOrEdit()"
          >
            新增
          </nh-button>
          <nh-button
            :track="{
              trackName: '基础数据/货主相关/货主信息/[同步货主]'
            }"
            @click="handleSynchro"
          >
            同步货主
          </nh-button>
        </template>
        <template #shipperCode="{row}">
          <nh-link
            to=""
            :text="row.shipperCode"
            @click="handleOnDetail(row.customerId)"
          />
        </template>
        <template #action="{row}">
          <nh-button type="text" @click="handleAddOrEdit(row.customerId)">
            编辑
          </nh-button>
          <nh-button type="text" @click="handleSetRule(row)">
            设置规则
          </nh-button>
          <nh-button
            type="text"
            :loading="loading.handleEnable"
            @click="handleEnable(row)"
          >
            {{ row.statusCode === '1' ? '停用' : '启用' }}
          </nh-button>
        </template>
      </PlTable>
    </pl-block>
    <shipper-create ref="shipperCreate" @onSuccess="getReservoirList" />
    <rule-setting ref="ruleSetting" @onSuccess="getReservoirList" />
    <detail ref="detail" />
    <synchro-shipper ref="synchroShipper" @onSuccess="getReservoirList" />
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';

import Base from '@/constant/pageNames/base';
import synchroShipper from './components/synchroShipper/index.vue';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import { getList, changeStatus } from './api';
import ShipperCreate from './components/shipperCreate.vue';
import RuleSetting from './components/ruleSetting.vue';
import Detail from './components/detail.vue';
import { CUSTOMER_STATUSES_ENUM, CUSTOMER_STATUSES } from './constant';

export default {
  name: Base.OWNER_ABOUT,
  components: {
    ShipperCreate, Detail, RuleSetting, synchroShipper,
  },
  mixins: [loadingMixin],
  data() {
    return {
      CUSTOMER_STATUSES_ENUM,
      TABLECOLUMN,
      serchFields,
      reservoirList: [],
      searchForm: {},
      visible: false,
      loading: {
        getReservoirList: false,
      },
    };
  },
  created() {
    this.getReservoirList();
  },
  methods: {
    handleSearch() {
      this.pagination.page = 1;
      this.getReservoirList();
    },
    async getReservoirList() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const result = await getList(this.searchForm, pagination);
      this.reservoirList = result.records;
      this.pagination.total = result.total;
    },
    handleOnDetail(id) {
      this.$refs.detail.init(id);
    },
    handleAddOrEdit(id) {
      this.$refs.shipperCreate.init(id);
    },
    handleSetRule(row) {
      this.$refs.ruleSetting.init(row);
    },
    async handleEnable(row) {
      const flag = row.statusCode === '1';
      try {
        await this.$confirm(
          `是否确认${flag ? '注销' : '启用'}${row.shipperName}货主?`,
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          },
        );
        await changeStatus({
          status: flag ? CUSTOMER_STATUSES[1].value : CUSTOMER_STATUSES[0].value,
          id: row.id,
        });
        this.$message({
          type: 'success',
          message: `${flag ? '注销' : '启用'}成功!`,
        });
        this.pagination.page = 1;
        this.getReservoirList();
      } catch {
        this.$message({
          type: 'success',
          message: '你已取消!',
        });
      }
    },
    handleSynchro() {
      this.$refs.synchroShipper.init();
    },
  },
};
</script>
<style scoped>
.action {
  padding: 15px 0;
}
</style>
