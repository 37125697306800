import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/12196
 * 新文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/18873
 */
export function getList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/v2/page', data, {
    params,
  });
}

/**
 * @description: 货主详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11440
 */
export function detail(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/detail', data);
}
/**
 * @description: 启用与注销
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/16641
 */
export function changeStatus(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/change/status', data);
}
/**
 * @description: 查询仓未绑定的客户信息列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/18549
 */
export function unboundCustomerList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/unbound_customer/page', data, { params });
}
/**
 * @description: 货主批量绑定与解绑仓库
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/16783
 */
export function shipperChangeBind(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/change/bind/batch', data);
}
