<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.init"
    :before-close="handleClose"
    destroy-on-close
    :track="{
      name_zh: '基础数据/货主相关/货主信息/抽屉-设置规则',
      trackName: $route.path
    }"
    title="货主信息"
    size="704px"
  >
    <el-form
      ref="enterpriseForm"
      :model="formData"
      class="owner-rule-setting"
      label-position="right"
    >
      <nh-drawer-container title="货主基础设置">
        <el-form-item label="默认批属性规则:" prop="baseRuleExt.defaultShelfLifeRule">
          <el-form-item
            label="保质期"
            label-width="80px"
            prop="baseRuleExt.defaultShelfLifeRule"
          >
            <nh-remote-select
              v-model="formData.baseRuleExt.defaultShelfLifeRule"
              :remote-method="handleRemoteRuleBatch"
              :loading="loading.handleemoteRuleBatch"
              class="select-width"
              value-key="ruleId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in defaultRuleOptions"
                :key="item.ruleId"
                :label="item.ruleName"
                :value="item"
              />
            </nh-remote-select>
          </el-form-item>
          <el-form-item
            label="非保质期"
            label-width="80px"
            prop="baseRuleExt.defaultNoneShelfLifeRule"
          >
            <nh-remote-select
              v-model="formData.baseRuleExt.defaultNoneShelfLifeRule"
              :remote-method="handleRemoteRuleBatch"
              :loading="loading.handleemoteRuleBatch"
              class="select-width"
              value-key="ruleId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in defaultRuleOptions"
                :key="item.ruleId"
                :label="item.ruleName"
                :value="item"
              />
            </nh-remote-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="是否允许缺货下单:" prop="baseRuleExt.oooFlag">
          <el-switch
            v-model="formData.baseRuleExt.oooFlag"
            active-value="ENABLED"
            inactive-value="DISABLED"
          />
        </el-form-item>
        <el-form-item label="是否允许缺货出库:" prop="baseRuleExt.oosFlag">
          <el-switch
            v-model="formData.baseRuleExt.oosFlag"
            active-value="ENABLED"
            inactive-value="DISABLED"
          />
        </el-form-item>
        <!-- <el-form-item
          v-if="formData.baseRuleExt.oosFlag == DICTIONARY.ENABLED "
          label="到货后补分配库存"
          prop="baseRuleExt.afterArrivalAllocateStock"
        >
          <el-radio-group v-model="formData.baseRuleExt.afterArrivalAllocateStock">
            <el-radio
              v-for="item in RULES_STOCK"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="入库抄码方式:" prop="baseRuleExt.receiptOrderCopyCodeType">
          <el-radio-group v-model="formData.baseRuleExt.receiptOrderCopyCodeType">
            <el-radio
              v-for="item in RECEIPT_ORDER_COPY_CODE_TYPE"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="出库抄码方式:" prop="baseRuleExt.deliverOrderCopyCodeType">
          <el-radio-group v-model="formData.baseRuleExt.deliverOrderCopyCodeType">
            <el-radio
              v-for="item in DELIVER_ORDER_COPY_CODE_TYPE"
              :key="item.value"
              :label="item.value"
            >
              {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="抄码误差范围:" prop="baseRuleExt.deviationPercentageCopyCode">
          <div class="deviation-percentage">
            <el-input-number
              v-model="formData.baseRuleExt.deviationPercentageCopyCode"
              :min="-1"
              :max="100"
              step="1"
              :precision="0"
              step-strictly
              :controls="false"
              class="deviation-input"
              :disabled="formData.baseRuleExt.deviationPercentageCopyCode === -1"
            />
            <el-checkbox
              v-model="formData.baseRuleExt.deviationPercentageCopyCode"
              :true-label="-1"
              :false-label="0"
            >
              不限制
            </el-checkbox>
          </div>
        </el-form-item>
        <el-form-item label="入库是否需要装卸:" prop="baseRuleExt.receiptOrderHandling">
          <el-switch
            v-model="formData.baseRuleExt.receiptOrderHandling"
          />
        </el-form-item>
        <el-form-item label="出库是否需要装卸:" prop="baseRuleExt.deliverOrderHandling">
          <el-switch
            v-model="formData.baseRuleExt.deliverOrderHandling"
          />
        </el-form-item>
        <el-form-item label="是否自动创建波次:" prop="baseRuleExt.autoCreateWave.isAutoCreateWave">
          <el-radio-group
            v-model="formData.baseRuleExt.autoCreateWave.isAutoCreateWave"
            class="create-wave_radio"
          >
            <div>
              <el-radio :label="true">
                是
              </el-radio>
              <el-form-item
                v-if="formData.baseRuleExt.autoCreateWave.isAutoCreateWave"
                prop="baseRuleExt.autoCreateWave.autoCreateWaveType"
              >
                <el-select
                  v-model="formData.baseRuleExt.autoCreateWave.autoCreateWaveType"
                  placeholder="请选择"
                  class="select-width"
                  clearable
                >
                  <el-option
                    v-for="item in autoCreateWave"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div>
              <el-radio :label="false">
                否
              </el-radio>
            </div>
          </el-radio-group>
        </el-form-item>
      </nh-drawer-container>
      <nh-drawer-container title="货主默认下架规则">
        <el-form-item
          label="下架分配规则:"
          prop="lowerLocationRuleExt.commonAppointRule.appointRule"
        >
          <nh-remote-select
            v-model="formData.lowerLocationRuleExt.commonAppointRule.appointRule"
            :remote-method="getLowerLocationOptions"
            value-key="ruleId"
            class="select-width"
            placeholder="请输入关键词"
            clearable
          >
            <el-option
              v-for="item in options.lowerOptions[0]"
              :key="item.ruleId"
              :label="item.ruleName"
              :value="item"
            />
          </nh-remote-select>
        </el-form-item>
      </nh-drawer-container>
      <nh-drawer-container title="货主默认上架规则">
        <el-form-item
          label="上架分配规则:"
          prop="upperLocationRuleExt.commonAppointRule.appointRule"
        >
          <nh-remote-select
            v-model="formData.upperLocationRuleExt.commonAppointRule.appointRule"
            value-key="ruleId"
            :remote-method="getUpLocationOptions"
            class="select-width"
            placeholder="请输入关键词"
            clearable
          >
            <el-option
              v-for="item in options.upperOptions[0]"
              :key="item.ruleId"
              :label="item.ruleName"
              :value="item"
            />
          </nh-remote-select>
        </el-form-item>
      </nh-drawer-container>
    </el-form>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          保存
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import { settingRule } from './api';
import { getRuleBatchAttribute } from '../../goods/api';
import { ruleStockAllocation, ruleUpperPage } from '../../goods/goodsInfo/components/api';

import {
  DICTIONARY,
  RULES_SET,
  RULES_STOCK,
  LOWER_SETTING_TYPE,
  UPPER_SETTING_TYPE,
  DELIVER_TYPE,
  RECEIPT_TYPE,
  RECEIPT_ORDER_COPY_CODE_TYPE,
  DELIVER_ORDER_COPY_CODE_TYPE,
} from './constant';

export default {
  name: 'OwnerRuleSetting',
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      drawerVisible: false,
      DICTIONARY,
      RULES_SET,
      RULES_STOCK,
      LOWER_SETTING_TYPE,
      UPPER_SETTING_TYPE,
      DELIVER_TYPE,
      RECEIPT_TYPE,
      RECEIPT_ORDER_COPY_CODE_TYPE,
      DELIVER_ORDER_COPY_CODE_TYPE,
      defaultRuleOptions: [],
      options: {
        lowerOptions: [[]],
        upperOptions: [[]],
      },
      formData: {
        baseRuleExt: {
          defaultRule: {},
          oosFlag: DICTIONARY.ENABLED,
          oooFlag: DICTIONARY.DISABLED,
          // afterArrivalAllocateStock: null,
          receiptOrderHandling: true,
          deliverOrderHandling: false,
          deviationPercentageCopyCode: 0,
          autoCreateWave: {
            isAutoCreateWave: true,
          },
        },
        lowerLocationRuleExt: {
          // settingType: LOWER_SETTING_TYPE[0].value,
          commonAppointRule: {
            appointRule: {},
          },
          // orderTypeAppointRules: [{
          //   orderType: null,
          //   appointRule: {},
          // }],
        },
        upperLocationRuleExt: {
          // settingType: UPPER_SETTING_TYPE[0].value,
          commonAppointRule: {
            appointRule: {},
          },
          // orderTypeAppointRules: [{
          //   orderType: null,
          //   appointRule: {},
          // }],
        },
      },
      loading: {
        handleSubmit: false,
      },
    };
  },
  computed: {
    autoCreateWave() {
      return this.$dictionaryFiledMap.AUTO_CREATE_WAVE || [];
    },
  },
  methods: {
    async init(data) {
      this.drawerVisible = true;
      const {
        id, baseRuleExt, lowerLocationRuleExt, upperLocationRuleExt,
      } = JSON.parse(JSON.stringify(data));
      await this.$nextTick();
      this.formData.id = id;
      if (baseRuleExt) {
        this.formData.baseRuleExt = {
          ...baseRuleExt,
          autoCreateWave: baseRuleExt.autoCreateWave ? baseRuleExt.autoCreateWave : {
            isAutoCreateWave: true,
            autoCreateWaveType: 'BY_ORDER',
          },
        };
      }
      // 默认保质期批属性规则
      const defaultShelfLifeRule = baseRuleExt?.defaultShelfLifeRule;
      // 默认非保质期批属性规则
      const defaultNoneShelfLifeRule = baseRuleExt?.defaultNoneShelfLifeRule;
      try {
        await this.handleRemoteRuleBatch();
      } finally {
        this.defaultRuleOptions
          .filter((item) => item.isDefault)
          .forEach((item) => {
            if (item.isShelfLifeRule) {
              this.formData.baseRuleExt.defaultShelfLifeRule = defaultShelfLifeRule || item;
            }
            if (item.isShelfLifeRule === false) {
              this.formData.baseRuleExt.defaultNoneShelfLifeRule = defaultNoneShelfLifeRule || item;
            }
          });
      }
      if (lowerLocationRuleExt && lowerLocationRuleExt.commonAppointRule
        && lowerLocationRuleExt.commonAppointRule.appointRule) {
        this.formData.lowerLocationRuleExt = lowerLocationRuleExt;
      }
      if (upperLocationRuleExt && upperLocationRuleExt.commonAppointRule
        && upperLocationRuleExt.commonAppointRule.appointRule) {
        this.formData.upperLocationRuleExt = upperLocationRuleExt;
      }
      const lowOptions = await this.getLowerOptions();
      // const lowRules = lowerLocationRuleExt?.orderTypeAppointRules || [];
      // if (lowRules.length > 0) {
      //   this.options.lowerOptions = lowRules.map(() => lowOptions);
      // } else {
      this.options.lowerOptions[0] = lowOptions;
      //   this.formData.lowerLocationRuleExt.orderTypeAppointRules = [
      //     { orderType: null, appointRule: {} },
      //   ];
      // }
      const upOptions = await this.getUpOptions();
      // const upperRules = upperLocationRuleExt?.orderTypeAppointRules || [];
      // if (upperRules.length > 0) {
      //   this.options.upperOptions = upperRules.map(() => upOptions);
      // } else {
      this.options.upperOptions[0] = upOptions;
      // this.formData.upperLocationRuleExt.orderTypeAppointRules = [
      //   { orderType: null, appointRule: {} },
      // ];
      // }
    },
    handleAdd(index, rule) {
      switch (rule) {
        case RULES_SET.LOWER_RULE:
          this.options.lowerOptions.splice(index + 1, 0, []);
          this.formData.lowerLocationRuleExt.orderTypeAppointRules
            .splice(index + 1, 0, { orderType: null, appointRule: {} });
          break;
        case RULES_SET.PUT_RULE:
          this.options.upperOptions.splice(index + 1, 0, []);
          this.formData.upperLocationRuleExt.orderTypeAppointRules
            .splice(index + 1, 0, { orderType: null, appointRule: {}, options: [] });
          break;
        default:
      }
    },
    handleDel(index, rule) {
      switch (rule) {
        case RULES_SET.LOWER_RULE:
          this.options.lowerOptions.splice(index, 1);
          this.formData.lowerLocationRuleExt.orderTypeAppointRules
            .splice(index, 1);
          break;
        case RULES_SET.PUT_RULE:
          this.options.upperOptions.splice(index, 1);
          this.formData.upperLocationRuleExt.orderTypeAppointRules
            .splice(index, 1);
          break;
        default:
      }
    },
    handleUpperSettingType(val) {
      if (val === UPPER_SETTING_TYPE[0].value) {
        this.formData.upperLocationRuleExt
          .orderTypeAppointRules = [{ orderType: null, appointRule: {} }];
      } else {
        this.formData.upperLocationRuleExt.commonAppointRule.appointRule = {};
      }
    },
    handleLowerSettingType(val) {
      if (val === LOWER_SETTING_TYPE[0].value) {
        this.formData.lowerLocationRuleExt
          .orderTypeAppointRules = [{ orderType: null, appointRule: {} }];
      } else {
        this.formData.lowerLocationRuleExt.commonAppointRule.appointRule = {};
      }
    },
    // 默认
    async handleRemoteRuleBatch(query) {
      const { records } = await getRuleBatchAttribute({ size: 100, page: 1 }, {
        needWarehouseRule: true,
        shipperId: this.formData.id,
        ruleName: query,
      });
      this.defaultRuleOptions = records
        .filter((item) => item.status === DICTIONARY.ENABLED)
        .map((list) => ({
          ruleId: list.id,
          ruleCode: list.ruleCode,
          ruleName: list.ruleName,
          isDefault: list.isDefault,
          isShelfLifeRule: list.isShelfLifeRule,
        }));
    },
    // 上架
    async getUpLocationOptions(val) {
      const options = await this.getUpOptions(val);
      // if (this.formData.upperLocationRuleExt.settingType === UPPER_SETTING_TYPE[0].value) {
      this.options.upperOptions[0] = options;
      // } else {
      //   this.options.upperOptions[index] = options;
      // }
    },
    async getUpOptions(val) {
      const { records = [] } = await ruleUpperPage({
        description: val,
      }, {
        page: 1,
        size: 100,
      });
      return records.map((item) => ({
        ruleId: item.id,
        ruleCode: item.ruleCode,
        ruleName: item.description,
      }));
    },
    // 下架
    async getLowerLocationOptions(val) {
      const options = await this.getLowerOptions(val);
      // if (this.formData.lowerLocationRuleExt.settingType === LOWER_SETTING_TYPE[0].value) {
      this.options.lowerOptions[0] = options;
      // } else {
      //   this.options.lowerOptions[index] = options;
      // }
    },
    async getLowerOptions(val) {
      const { records = [] } = await ruleStockAllocation({ description: val }, {
        page: 1,
        size: 100,
      });
      return records.map((item) => ({
        ruleId: item.id,
        ruleCode: item.ruleCode,
        ruleName: item.description,
      }));
    },
    async handleSubmit() {
      await settingRule(this.formData);
      this.$emit('onSuccess');
      this.handleClose();
    },
    handleClose() {
      this.$refs.enterpriseForm.resetFields();
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.owner-rule-setting {
  .select-width {
    width: 240px;
  }

  .list-box + div {
    border-top: 1px solid #ebedf0;
    padding-top: 16px;
  }

  .create-wave_radio {
    display: flex;
    flex-direction: column;

    & > div {
      display: flex;

      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}

.deviation-percentage {
  display: flex;
  align-items: center;

  .deviation-input {
    margin-right: 20px;
    width: 100px;
  }
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
