<template>
  <nh-drawer
    v-model="drawerVisible"
    v-loading="loading.init"
    :before-close="handleClose"
    destroy-on-close
    :track="{
      name_zh: '基础数据/货主相关/货主信息/抽屉-同步货主',
      trackName: $route.path
    }"
    title="客户信息"
    size="1112px"
  >
    <pl-block>
      <PlForm
        v-model="searchQuery"
        mode="search"
        :fields="serchFields"
        @submit="handleQuery"
        @reset="handleQuery"
      />
    </pl-block>
    <pl-block>
      <PlTable
        v-model:pagination="pagination"
        :loading="loading.getTableData"
        :data="tableData"
        :columns="TABLECOLUMN"
        @update:pagination="getTableData"
        @selection-change="handleSelectionChange"
      />
    </pl-block>
    <template #footer>
      <div class="footer">
        <nh-button
          type="primary"
          :loading="loading.handleSubmit"
          @click="handleSubmit"
        >
          确认同步
        </nh-button>
        <nh-button
          :loading="loading.handleSubmit"
          @click="handleClose"
        >
          关闭
        </nh-button>
      </div>
    </template>
  </nh-drawer>
</template>
<script>
import loadingMixin from '@thales/loading';
import { mapState } from 'vuex';
import { TABLECOLUMN, serchFields } from './fileds';
import { unboundCustomerList, shipperChangeBind } from '../../api';

export default {
  name: 'SynchroShipper',
  mixins: [loadingMixin],
  emits: ['onSuccess'],
  data() {
    return {
      TABLECOLUMN,
      serchFields,
      drawerVisible: false,
      searchQuery: {
        customerCode: undefined,
        customerNameLike: undefined,
        corpNameLike: undefined,
      },
      loading: {
        getTableData: false,
        handleSubmit: false,
      },
      selectList: [],
      selectCodes: [],
      tableData: [],
    };
  },

  computed: {
    ...mapState({
      accountInfo: ({ user }) => user.accountInfo,
    }),
  },
  methods: {
    async init() {
      this.drawerVisible = true;
      this.handleQuery();
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const data = {
        warehouseCode: this.accountInfo.ext.warehouseCode,
        ...this.searchQuery,
      };
      const result = await unboundCustomerList(data, pagination);
      this.tableData = result.records;
      this.pagination.total = result.total;
    },
    handleSelectionChange(selection) {
      this.selectList = selection;
    },
    async handleSubmit() {
      const changeBindInfos = this.selectList.map((item) => ({
        customerId: item.id,
        customerCode: item.customerCode,
        warehouseId: this.accountInfo.ext.warehouseId,
        isBound: true,
      }));
      await shipperChangeBind({ changeBindInfos });
      this.$message({
        type: 'success',
        message: '操作成功',
      });
      this.$emit('onSuccess');
      this.handleClose();
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
