import { formatKeyValueArray } from '@/utils/base';

const COOPERATIVE_NATURE_ENUM = { 2: '零担', 1: '合约' };
// 分页接口为老数据 启用： ‘1’； 停用：‘2’
const CUSTOMER_STATUSES_ENUM = {
  ENABLED: '已启用',
  DISABLED: '已停用',
};
const CUSTOMER_STATUSES = formatKeyValueArray(CUSTOMER_STATUSES_ENUM);
// 业务线
const BUSINESS_LIST_ENUM = {
  'crmBusiness-settlement': '结算公司',
  'crmBusiness-cold': '平台服务',
  'crmBusiness-contract': '合约关联',
  'crmBusiness-outcontract': '合约外部',
};

export {
  COOPERATIVE_NATURE_ENUM,
  CUSTOMER_STATUSES_ENUM, CUSTOMER_STATUSES,
  BUSINESS_LIST_ENUM,
};
