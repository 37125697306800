import { formatKeyValueArray, formatKeyValueObject } from '@/utils/base';
import { StoreBillTypeText } from '@/constant/task';
import { BUSINESS_LIST_ENUM, COOPERATIVE_NATURE_ENUM } from '../constant';

// 货主类型
const SHIPPER_TYPE_ENUM = {
  company: '企业',
};
const PASS = 'PASS';
const SHIPPER_TYPE = formatKeyValueArray(SHIPPER_TYPE_ENUM);
const COOPERATIVE_NATURE = formatKeyValueArray(COOPERATIVE_NATURE_ENUM);
const COOPERATIVE_CODE = [{
  label: '零担',
  code: 4,
  value: 2,
}, {
  label: '合约',
  code: 2,
  value: 1,
}];
const COOPERATIVE_CODE_ENUM = formatKeyValueObject(COOPERATIVE_CODE, { value: 'value', label: 'code' });
// 行业
const INDUSTRY_LIST_ENUM = {
  // 'crmIndustry-logistics': '物流服务',
  // 'crmIndustry-meal': '餐饮',
  // 'crmIndustry-retail': '零售',
  // 'crmIndustry-trade': '工贸',
  1: '食品工贸',
  2: '中餐连锁',
  3: '西餐连锁',
  4: '休闲专卖',
  5: '零售电商',
  6: '关联业务',
  7: '行业服务',
};
const INDUSTRY_LIST = formatKeyValueArray(INDUSTRY_LIST_ENUM);
// 业务
const BUSINESS_LIST = formatKeyValueArray(BUSINESS_LIST_ENUM);

const DICTIONARY = {
  ENABLED: 'ENABLED', // 批属性生效
  DISABLED: 'DISABLED',
};
// 规则
const RULES_SET = {
  PUT_RULE: '货主默认上架规则',
  LOWER_RULE: '货主默认下架规则',
};
// 货主类型
const RULES_STOCK_ENUM = {
  NOT_ALLOCATE: '不分配',
  BEFORE_TOTAL_PICK_FINISH: '总拣完成前',
  BEFORE_LOAD_FINISH: '装车完成前',
  BEFORE_SHIP: '发运前',
};
const RULES_STOCK = formatKeyValueArray(RULES_STOCK_ENUM);
const LOWER_SETTING_TYPE_ENUM = {
  COMMON: '通用',
  ORDER_TYPE: '按出库单据类型',
};
const LOWER_SETTING_TYPE = formatKeyValueArray(LOWER_SETTING_TYPE_ENUM);
const UPPER_SETTING_TYPE_ENUM = {
  COMMON: '通用',
  ORDER_TYPE: '按入库单据类型',
};
const UPPER_SETTING_TYPE = formatKeyValueArray(UPPER_SETTING_TYPE_ENUM);
const DELIVER_TYPE_ENUM = {
  ORDER_OUT: '订单出库',
  ADJUST_OUT: '调整出库',
  INVENTORY_LESS: '盘亏出库',
  DESTROY_OUT: '销毁出库',
  CANCEL_OUT: '退供出库',
  OTHER_IT_OUT: '其它出库',
  JYP_OUT: '经由品出库',
};
const DELIVER_TYPE = formatKeyValueArray(DELIVER_TYPE_ENUM);

const RECEIPT_TYPE = formatKeyValueArray(StoreBillTypeText);
const IMAGE_TYPE = ['png', 'jpg', 'jpeg'];

const RECEIPT_ORDER_COPY_CODE_TYPE_ENUM = {
  BOX: '按箱抄码',
  GOODS: '按品抄码',
};
const RECEIPT_ORDER_COPY_CODE_TYPE = formatKeyValueArray(RECEIPT_ORDER_COPY_CODE_TYPE_ENUM);
const DELIVER_ORDER_COPY_CODE_TYPE_ENUM = {
  ORDER: '按单抄码',
  PICKING: '总拣抄码',
};
const DELIVER_ORDER_COPY_CODE_TYPE = formatKeyValueArray(DELIVER_ORDER_COPY_CODE_TYPE_ENUM);

const CUSTOMER_SOURCE = {
  SOURCE_CRM: '来自crm',
  SOURCE_WMS: 'wms初始导入',
  SOURCE_WMS_WEB: 'wms web 页面创建',
  SOURCE_GUOPAN_MINIAPP: '果盘货主小程序',
  SOURCE_CARRIER_ORDER: '承运商下单',
  SOURCE_PLATFORM: '平台',
  SOURCE_PLATFORM_AUTH_PASS: '平台审核通过',
};

export {
  DICTIONARY,
  PASS,
  RULES_STOCK, RULES_STOCK_ENUM, // 分配库存
  LOWER_SETTING_TYPE, LOWER_SETTING_TYPE_ENUM, // 出库单配置方式
  DELIVER_TYPE, // 单据类型 出库
  UPPER_SETTING_TYPE, UPPER_SETTING_TYPE_ENUM, // 入库单配置方式
  RECEIPT_TYPE, // 单据类型 入库
  RULES_SET, // 规则
  SHIPPER_TYPE_ENUM, SHIPPER_TYPE, // 货主类型
  COOPERATIVE_NATURE, // 合作性质
  COOPERATIVE_CODE_ENUM, // 合作性质, 新增传参项
  INDUSTRY_LIST_ENUM, INDUSTRY_LIST, // 行业
  BUSINESS_LIST, // 业务
  IMAGE_TYPE,
  RECEIPT_ORDER_COPY_CODE_TYPE, // 入库单抄码方式
  DELIVER_ORDER_COPY_CODE_TYPE, // 出库单抄码方式
  CUSTOMER_SOURCE, // 货主来源
};
