import { wmsPlusHttp, otmsHttp } from '@/utils/http/index';

/**
 * 货主详情
 * https://yapi.ops.yunlizhi.cn/project/388/interface/api/16637
 */
export function getCustomerDetail(data) {
  return wmsPlusHttp.post('/warehouse_foundation/customer/detail', data);
}
/**
 * 新增/修改货主
 *  https://yapi.ops.yunlizhi.cn/project/388/interface/api/16639
*/
export function editCustomer(data) {
  return wmsPlusHttp.post('/warehouse_foundation/customer/upsert', data);
}

/**
 * 城市数据集
 * https://yapi.ops.yunlizhi.cn/project/130/interface/api/15191
 */
export function getCityAreaTree(data) {
  return otmsHttp.post('base_plus/city_area/tree', data);
}
/**
 * POI列表
 * https://yapi.ops.yunlizhi.cn/project/130/interface/api/8552
 */
export function poiSearch(data) {
  return otmsHttp.post('/poi/list', data);
}
/**
 * 设置规则
 * https://yapi.ops.yunlizhi.cn/project/388/interface/api/16665
 */
export function settingRule(data) {
  return wmsPlusHttp.post('/warehouse_foundation/shipper/setting/rule', data);
}
/**
 * 企业二要素认证
 * https://yapi.ops.yunlizhi.cn/project/274/interface/api/10598
 */
export function enterprisetTwoElements(data) {
  return otmsHttp.post('bfs_identity/enterprise/identity/two_elements', data);
}
