import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11442
 */
export function getList(data, params) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/page', data, {
    params,
  });
}

/**
 * @description: 货品分类
 * @api文档：https://yapi.ops.yunlizhi.cn/project/271/interface/api/9292
 */
export function getGoodsLevelTree(data) {
  return wmsPlusHttp.post('/goods_level/tree', data);
}
/**
 * @description: 查看详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11350
 */
export function getShipperDetail(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/detail', data);
}
/**
 * @description: 库位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11180
 */
export function storehousePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/storehouse/page', data, {
    params,
  });
}
/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}
/**
 * @description: 逻辑库区 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11248
 */

export function abstractZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/abstract_zone/page', data, {
    params,
  });
}
/**
 * @description: 商品表编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11348
 */

export function warehouseGoodsUpsert(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/upsert', data);
}
/**
 * @description: 下架  库存分配规则 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11516
 */

export function ruleStockAllocation(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_stock_allocation/page', data, {
    params,
  });
}
/**
 * @description: 批属性规则 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11758
 */

export function getRuleBatchAttribute(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/rule_batch_attribute/page', data, {
    params,
  });
}

/**
 * @description: 导入货品
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/15407
 *
 */
export function warehouseGoodsImport(data) {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_goods/import', data, { });
}

/**
 * @description: 下载模板
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12508
 *
 */
export function downLoadTemplate(data) {
  return wmsPlusHttp.download('/warehouse_management_system/file/template/download', data, { fileName: '导入货品模板', extension: 'xlsx' });
}

/**
 * @description: 导出货品
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/15473
 *
 */
export function warehouseGoodsExport(data) {
  return wmsPlusHttp.download('/warehouse_foundation/warehouse_goods/export', data, { fileName: '货品', extension: 'xlsx' });
}
