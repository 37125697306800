import { FormType } from '@/constant/form';
import { CUSTOMER_SOURCE } from '../constant';

export const TABLECOLUMN = [{
  type: 'selection',
  width: 50,
  prop: 'selection',
}, {
  label: '客户编码',
  prop: 'customerCode',
  minWidth: 180,
}, {
  label: '货主名称',
  prop: 'customerName',
  minWidth: 120,
}, {
  label: '公司名称',
  prop: 'corpName',
  minWidth: 120,
}, {
  label: '客户类型',
  prop: 'customerType',
  minWidth: 160,
}, {
  label: '来源',
  prop: 'tags',
  minWidth: 180,
  formatter: ({ tags }) => tags.map((item) => CUSTOMER_SOURCE[item]).join('、'),
},
];

export const serchFields = [{
  label: '客户编码',
  prop: 'customerCode',
  component: FormType.INPUT,
}, {
  label: '货主名称',
  prop: 'customerNameLike',
  component: FormType.INPUT,
}, {
  label: '公司名称',
  prop: 'corpNameLike',
  component: FormType.INPUT,
},
];
