import {
  validatePhone, validateCode, validateTaxpayerNo,
  validateInvoiceTelPhone, validateAccount,
} from '@/utils/validatorForm';

const rules = {
  // 货主编码
  customerCode: [
    { validator: validateCode, trigger: 'blur' },
  ],
  corpName: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  customerName: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  creditCode: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  contacts: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  telPhone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { validator: validatePhone, trigger: 'blur' },
  ],
  'ext.industry': [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  'ext.bizType': [
    { required: true, message: '请选择', trigger: 'change' },
  ],
  provinces: [
    {
      type: 'array', required: true, message: '请选择', trigger: 'change',
    },
  ],
  address: [
    { required: true, message: '请输入', trigger: 'blur' },
  ],
  // 'ext.businessLicenseUrl': [
  //   { required: true, message: '请上传', trigger: 'change' },
  // ],
  taxpayerNo: [
    { validator: validateTaxpayerNo, trigger: 'blur' },
  ],
  invoiceTelPhone: [
    { validator: validateInvoiceTelPhone, trigger: 'blur' },
  ],
  account: [
    { validator: validateAccount, trigger: 'blur' },
  ],
};
const baseInfo = [{
  prop: 'customerCode',
  label: '货主自有编码',
  span: 8,
}, {
  prop: 'customerName',
  label: '货主名称',
  span: 8,
}, {
  prop: 'corpName',
  label: '企业名称',
  span: 8,
}, {
  prop: 'creditCode',
  label: '统一社会信用代码',
  span: 24,
}, {
  prop: 'contacts',
  label: '联系人姓名',
  span: 8,
}, {
  prop: 'telPhone',
  label: '联系人电话',
  span: 8,
}, {
  prop: 'industry',
  label: '行业类型',
  span: 8,
}, {
  prop: 'address',
  label: '联系地址',
}];
const billingInfo = [
  {
    prop: 'invoice',
    label: '名称',
  }, {
    prop: 'taxpayerNo',
    label: '纳税人识别号',
  }, {
    prop: 'invoiceTelPhone',
    label: '电话号码',
  },
  {
    prop: 'invoiceAddress',
    label: '联系地址',
  },
  {
    prop: 'bank',
    label: '开户银行',
  },
  {
    prop: 'account',
    label: '银行账号',
  },
];

export {
  rules,
  baseInfo,
  billingInfo,
};
